<template>
  <div class="mt-8">
    <v-row class="ml-3 mr-4">
      <v-col class="text-left pl-0" cols="12">
        <div class="d-flex">
          <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
            <img loading="lazy" width="15" src="../assets/icon/navigate_before.png" />
          </v-btn>
          <p class="text-title-1">
            <label
              style="padding-top: 30%"
              class="text-service-blue-sm txt-capitalize"
            >
              {{ name }},
            </label>
            <label style="padding-top: 30%" class="text-service-blue-sm">
              acá podrá
            </label>
          </p>
        </div>
        <p class="text-service-sm mb-1 ml-9">Realizar todas sus solicitudes</p>
      </v-col>
      <v-col cols="12">
        <v-row>
          <template v-for="data in listService">
            <v-col cols="4" sm="2" md="2" lg="2" xl="2" :key="data.id" v-if="data.show==true">
              <div
                style="text-align: -webkit-center"
                @click="go(data)"
                class="div-service"
                :class="data.class"
              >
                <div class="pt-3">
                  <v-img  loading="lazy" width="40" :src="data.icon"></v-img>
                  <p class="text-services-name pt-0 mb-0">{{ data.name }}</p>
                </div>
              </div>
            </v-col>
          </template>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2">
            <div
              style="text-align: -webkit-center"
              @click="irLogin()"
              class="div-service"
            >
              <div class="pt-3">
                <v-img
                  loading="lazy"
                  width="40"
                  :src="origin + '/icon/logout-mobile.svg'"
                ></v-img>
                <p class="text-services-name pt-0 mb-0">Cerrar Sesión</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Api from "@/utils/api";
import moment from "moment";
export default {
  data() {
    return {
      origin: window.location.origin,
      infoToken: {
        document: "",
      },
      name: "",
      listService: [
        {
          id: 1,
          icon: origin + "/icon/service-2.svg",
          name: "Actualidad BRM",
          router: "listNews",
          class: "v-step-mobile-13",
          show:true
        },
        {
          id: 9,
          icon: origin + "/icon/service-8.svg",
          name: "Pódcast",
          router: "podcast",
          class: "",
          show:true
        },
        {
          id: 6,
          icon: origin + "/icon/document-text-2.svg",
          name: "Documentos corporativos",
          router: "documents",
          class: "v-step-mobile-18",
          show:true
        },
        {
          id: 2,
          icon: origin + "/icon/service-3.svg",
          name: "BRM Pregunta",
          router: "forms",
          class: "v-step-mobile-14",
          show:true
        },
        {
          id: 5,
          icon: origin + "/icon/service-1.svg",
          name: "Solicitudes",
          router: "actionToday",
          class: "v-step-mobile-17",
          show:true
        },
        {
          id: 3,
          icon: origin + "/icon/service-4.svg",
          name: "Control de vida",
          router: "lifeControl",
          class: "v-step-mobile-15",
          show:true
        },
        {
          id: 4,
          icon: origin + "/icon/Perfil.svg",
          name: "Mi perfil empresarial",
          router: "profile",
          class: "v-step-mobile-16",
          show:true
        },
        {
          id: 7,
          icon: origin + "/icon/comission-mobile.svg",
          name: "Reporte de nómina",
          router: "marginCommisions",
          class: "",
          show:true
        },
        {
          id: 8,
          icon: origin + "/icon/profile-remove-line.svg",
          name: "Retiro voluntario",
          router: "voluntaryRetirement",
          class: "",
          show:true
        },
        {
          id: 10,
          icon: origin + "/icon/update-grey.svg",
          name: "Codigo carnet",
          router: "carnet",
          class: "",
          show:true
        },
      ],
    };
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    go(data) {
      this.$router.push({ name: data.router });
    },
    irLogin() {
      let dispositivo;
      if (screen.width < 1024) {
        dispositivo = "Mobile";
      } else {
        dispositivo = "Desktop";
      }
      const record = {
        userid: this.infoToken.document,
        appid: "80",
        appname: "Logout Okan",
        date: moment().format("YYYY-MM-DD"),
        dispositivo,
      };
      Api.noAuth()
        .saveAppActivity(record)
        .then((resp) => resp.json())
        .then((data) => {
          localStorage.clear();
          sessionStorage.clear();
          var min = 12300;
          var max = 34290;
          var noCache = Math.floor(Math.random()*(max-min+1)+min);
          window.location.href = process.env.VUE_APP_FT_LOGOUT + '/?noCache="'+ noCache +'"';
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getToken();
  },
};
</script>
<style lang="less" scoped>
@import "../assets/css/main";
.div-service {
  width: 80px;
  // height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(62, 123, 250, 0.2),
    0px 2px 4px rgba(70, 107, 227, 0.4);
  border-radius: 10px;
}
.text-service-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
  // font-family: "proximaNovaRegular";
}
.text-service-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "AsapRegular";
}
.text-services-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #466be3;
  font-family: "proximaNovaRegular";
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
